<template>
  <div class="container">
    <!-- 账户数据 -->
    <div class="pa-24 bg-white">
      <div class="d-flex" style="overflow-x: auto">
        <div class="card-info">
          <div>卡账户总额(元)</div>
          <div class="mt-16 mb-32 fz-24 fw-500">
            {{ amountDispose(amountData.total_balance) }}
          </div>
          <div>
            <a-button
              v-if="$store.getters.roles.includes('DataCheckCostWarn')"
              type="primary"
              @click="showVisible"
              >费用预警</a-button
            >
          </div>
        </div>
        <div class="card-info">
          <div>可支撑交易(万元)</div>
          <div class="mt-16 fz-24 fw-500">
            {{
              amountData.tradable_amount &&
                (amountData.tradable_amount / 1000000).toFixed(2)
            }}
          </div>
        </div>
        <div class="card-info">
          <div>即将到期(元)</div>
          <div class="mt-16 mb-32 fz-24 fw-500">
            {{ amountDispose(amountData?.accounts?.balance) }}
          </div>
          <div>
            到期时间：{{
              dateTime(amountData?.accounts?.valid_end, "YYYY-MM-DD")
            }}
          </div>
        </div>
        <div class="card-info">
          <div>
            本月充值(元): {{ fenToYuan(amountData.current_month_recharge) }}
          </div>
          <div class="mt-30 mb-32">
            本月赠送(元): {{ fenToYuan(amountData.current_month_given) }}
          </div>
          <div>
            本月消费(元): {{ fenToYuan(amountData.current_month_counsume) }}
          </div>
        </div>
      </div>
      <!-- 消费趋势 -->
      <div class="mt-16 pa-24 bda bdrs-4">
        <div class="d-flex justify-space-between">
          <div class="fw-500 title">近6个月消费趋势(元)</div>
          <a
            v-if="$store.getters.roles.includes('ServiceOrder')"
            @click="changeRoute('/order/service-order')"
          >
            查看消费账单
          </a>
        </div>
        <EchartLine
          :serviceXaxis="serviceXaxis"
          :serviceSeries="serviceSeries"
        />
      </div>
      <!-- 充值记录 -->
      <div class="my-16 px-24 pt-24 pb-12 bda bdrs-4">
        <div class="d-flex justify-space-between mb-20">
          <div class="fw-500 title">充值记录</div>
          <div>
            充值时间
            <a-range-picker format="YYYY-MM-DD" @change="handleTimeChange" />
          </div>
        </div>
        <p-table
          rowKey="id"
          dataKey="modal"
          :dataSource="dataSource"
          :columns="tableColumns"
        />
      </div>
      <!-- 发卡记录 -->
      <div class="my-16 px-24 pt-24 pb-12 bda bdrs-4">
        <div class="d-flex justify-space-between mb-20">
          <div class="fw-500 title">我的消费卡</div>
          <a
            v-if="$store.getters.roles.includes('EnterpriseDetail')"
            @click="
              changeRoute(
                '/customer/enterprise-detail?account_service=DATA_CHECK'
              )
            "
            >查看全部发放记录</a
          >
        </div>
        <div
          style="width: 100%;overflow-x: scroll;overflow-y: hidden;white-space: nowrap;"
        >
          <div
            class="account-cards mr-16"
            v-for="(item, index) in cardList"
            :key="index"
          >
            <div class="px-24 py-16" :style="{ background: item.color }">
              <p class="fz-16 fw-600">消费卡-{{ item.account_service_desc }}</p>
              <p class="mt-12 fz-14" style="line-height:16px;">
                {{ item.card_no }}
              </p>
              <p class="mt-14 fz-22" style="line-height:34px;">
                <span class="fz-12">￥</span>{{ toConversion(item.balance) }}元
              </p>
              <div class="mt-24">
                <ul class="d-flex justify-space-between">
                  <li class="d-flex align-center">
                    <p class="fz-12 mr-6" style="opacity: 0.7;">面值</p>
                    <p>{{ toConversion(item.recharge_amount) }}元</p>
                  </li>
                  <li class="d-flex align-center">
                    <p class="fz-12 mr-6" style="opacity: 0.7;">有效期至</p>
                    <p>{{ dateTime(+item.valid_end) }}</p>
                  </li>
                </ul>
              </div>
              <div class="account-card">
                <img src="@/assets/image/card.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 预警弹窗 -->
    <a-modal
      v-model:visible="visible"
      title="费用预警"
      @ok="handleOk"
      @cancel="
        visible = false;
        Message.success('已取消费用预警');
      "
    >
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-item label="接收提醒" name="status">
          <Switch v-model:checked="formState.status" />
        </a-form-item>
        <a-form-item>
          <p>
            当通用账户余额小于设定阙值时，系统将每天向接收人手机号发送短信提醒，最多连续提醒3天。
          </p>
        </a-form-item>
        <a-form-item label="预警阈值" name="threshold">
          <a-input v-model:value="formState.threshold" suffix="元" />
        </a-form-item>
        <a-form-item label="接收人手机号" name="message_receiver">
          <a-input v-model:value="formState.message_receiver" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import EchartLine from "./EcharsLine";
import { Table } from "@wlhy-web-components/common";
import { getColumns } from "./table-config";
import { GetRequest } from "@/utils/requestUrl";
import { amountDispose, fenToYuan } from "@/hooks/money";
import { getBeforeTime } from "@/utils/hooks/beforeTime";
import { dateTime } from "@/hooks/time";
import {
  getLandUserInfoApi,
  useAccountQueryApi,
  useAccountAmountDetailApi,
  useRechargeListApi,
  useAlarmCreateConfigApi,
  useAlarmQueryConfigApi,
  useAlarmUpdateConfigApi,
  useAccountByMonthApi
} from "@/apis/land";
import { message, Switch } from "ant-design-vue";
import { getFormRules } from "../LandPage/constants/table-config";
import { toConversion } from "@/utils/hooks/conversion";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {
    EchartLine,
    Switch
  },
  setup() {
    const router = useRouter();
    const amountData = ref({});
    const rules = getFormRules();
    const accountAmountDetailApi = useAccountAmountDetailApi();

    const serviceXaxis = ref([]);
    const serviceSeries = ref([]);
    const { oneYear } = getBeforeTime();
    serviceXaxis.value = oneYear.slice(6);
    const mapData = {};

    const accountByMonthApi = useAccountByMonthApi();
    // 获取消费趋势
    const getConsumeData = async () => {
      let res = await accountByMonthApi({
        end_ym: serviceXaxis.value[5],
        start_ym: serviceXaxis.value[0],
        service_group: "DATA_CHECK"
      });
      res.map(item => {
        mapData[item.operating_time] = item.money;
      });

      //key=》value
      serviceXaxis.value?.map(key => {
        let value = mapData[key] || 0;
        serviceSeries.value.push(value ? value / 100 : value);
      });
    };

    const getUserInfo = () => {
      getLandUserInfoApi().then(res => {
        // companyId.value = res.company.id;
        // userInfo.value = res;
        console.log(res);
      });
    };
    // 获取url携带的token
    const token = GetRequest();
    const goVasUrl = token
      ? `/api/portal/company/login?token=${token}&redirect_url=${window.location.origin}/customer/enterprise`
      : "";
    const redirect = ref(
      `/api/portal/company/login?token=${token}&redirect_url=${window.location.origin}`
    );
    //获取充值记录table数据
    const rechargeListApi = useRechargeListApi();
    const tableDataSource = reactive({
      data: [],
      error: null,
      loading: false,
      meta: {
        total: 0,
        pageSize: 5,
        currentPageNo: 1
      }
    });
    const tableColumns = getColumns();
    const filter = ref({
      start_time: "",
      end_time: ""
    });
    const getConsultingList = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const res = await rechargeListApi({
          account_service: "DATA_CHECK",
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo,
          sort: "binding_time desc",
          is_given: "N",
          ...filter.value
        });
        return { records: res.list, total: res.total };
      }
    });
    const handleTimeChange = data => {
      filter.value = {
        start_time: data[0]?.valueOf(),
        end_time: data[1]?.valueOf()
      };
      getConsultingList();
    };

    const getAccountInfo = async () => {
      const res = await accountAmountDetailApi({
        account_service: "DATA_CHECK"
      });
      amountData.value = {
        ...res,
        accounts: res.accounts && res.accounts[0]
      };
    };
    // 卡列表
    const cardList = ref([]);
    const accountQueryApi = useAccountQueryApi();
    const getCardList = async () => {
      const res = await accountQueryApi({
        account_service: "DATA_CHECK",
        sort: "valid_end asc",
        size: 4,
        page: 1,
        query_amount_flag: true
      });
      cardList.value =
        res &&
        res.list.map(item => {
          if (item.account_service === "ETC") {
            item.color = "#F3535D";
          } else if (item.account_service === "OCR") {
            item.color = "#5A57FA";
          } else if (item.account_service === "GEO") {
            item.color = "#794CD9";
          } else if (item.account_service === "SMS") {
            item.color = "#84BF07";
          } else if (item.account_service === "VERIFY") {
            item.color = "#FF9E2B"; //"#FF8329"
          } else if (item.account_service === "SIGN") {
            item.color = "#036BE7";
          } else if (item.account_service === "DATA_CHECK") {
            item.color = "#4A4A4A";
          } else {
            item.color = "#1684FB";
          }
          return item;
        });
    };

    // 预警弹窗
    const visible = ref(false);
    const formState = ref({
      status: true, //是否开启
      message_receiver: "", //消息接收人
      threshold: "" //阈值
    });

    const alarmCreateConfig = useAlarmCreateConfigApi();
    const alarmQueryConfig = useAlarmQueryConfigApi();
    const alarmUpdateConfig = useAlarmUpdateConfigApi();
    let changeCongidFun;
    //记录查询公司的信息  以便使用id
    const queryConfigData = ref();

    //回显
    const writeToBrowser = res => {
      formState.value.status = res.status;
      formState.value.message_receiver = res.message_receiver;
      formState.value.threshold = res.threshold && res.threshold / 100;
    };

    //查询公司预警信息
    const showVisible = () => {
      visible.value = true;
      getAlarmInfo("DATA_CHECK");
    };

    const getAlarmInfo = async service_group => {
      let res;
      try {
        res = await alarmQueryConfig({ service_group });
        queryConfigData.value = res;
        if (res) {
          //TODO:回显
          writeToBrowser(res);
          changeCongidFun = alarmUpdateConfig;
        } else {
          writeToBrowser({});
          changeCongidFun = alarmCreateConfig;
        }
      } catch {
        changeCongidFun = alarmCreateConfig;
      }
    };
    const formRef = ref(null);
    const handleOk = async () => {
      formRef.value
        .validate()
        .then(async () => {
          let data = {
            ...formState.value,
            service_group: "DATA_CHECK",
            id: queryConfigData.value?.id
          };
          //金额*100
          data.threshold = data.threshold * 100;
          data.status = data.status ? "ENABLED" : "INVALID";
          await changeCongidFun(data);
          message.success("费用预警设置设置成功");
          visible.value = false;
        })
        .catch(err => {
          console.log(err);
        });
    };

    onMounted(() => {
      getUserInfo();
      getConsultingList();
      getAccountInfo();
      getConsumeData();
      getCardList();
    });

    const changeRoute = path => {
      router.push({
        path: path
      });
    };

    return {
      changeRoute,
      formRef,
      serviceXaxis,
      serviceSeries,
      dataSource: tableDataSource,
      tableColumns,
      cardList,
      goVasUrl,
      amountData,
      redirect,
      amountDispose,
      fenToYuan,
      dateTime,
      filter,
      handleTimeChange,
      getAccountInfo,
      getConsumeData,
      showVisible,
      getAlarmInfo,
      handleOk,
      rules,
      formState,
      visible,
      toConversion
    };
  }
});
</script>

<style lang="less" scoped>
.container {
  .card-info {
    width: 300px;
    background: #f7f8fa;
    padding: 24px;
    border-radius: 4px;
    margin-right: 16px;
    white-space: nowrap;
  }
  .title {
    position: relative;
  }
  .title::before {
    content: "";
    height: 14px;
    width: 2px;
    background: #007aff;
    position: absolute;
    top: 4px;
    left: -9px;
  }
  .account-cards {
    position: relative;
    display: inline-block;
    height: 175px;
    width: 302px;
    background: #1684fb;
    border-radius: 10px;
    color: #fff;
    overflow: hidden;
  }
  .account-card {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 10;
  }
}
</style>
