import moment from "moment";

export const dateTime = (time, format = "YYYY.MM.DD") => {
  if (time) {
    return moment(time).format(format);
  }
};

export const getTime = type => {
  let times = {
    start_time: "",
    end_time: ""
  };
  switch (type) {
    case "month":
      {
        times.start_time = moment()
          .startOf("month")
          .valueOf();
        times.end_time = moment().valueOf();
      }
      break;
    case "lastMonth":
      {
        times.start_time = moment()
          .subtract(1, "month")
          .startOf("month")
          .valueOf();
        times.end_time = moment()
          .subtract(1, "month")
          .endOf("month")
          .valueOf();
      }
      break;
    case "year":
      {
        times.start_time = moment()
          .startOf("year")
          .valueOf();
        times.end_time = moment().valueOf();
      }
      break;
  }
  return times;
};
