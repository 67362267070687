import { fenToYuan } from "../../hooks/money";
export const getColumns = () => {
  const columns = [
    { type: "checkbox", hidden: true },
    {
      width: 80,
      type: "seq",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "充值金额(元)",
      dataIndex: "amount",
      minWidth: 180,
      align: "right",
      customRender: val => (val ? fenToYuan(val) : "")
    },
    {
      title: "充值后余额(元)",
      dataIndex: "balance",
      minWidth: 180,
      align: "right",
      customRender: val => (val ? fenToYuan(val) : "")
    },
    {
      title: "生效时间",
      field: "valid_start",
      minWidth: 180,
      cellRender: "p-date"
    },
    {
      title: "充值时间",
      field: "binding_time",
      minWidth: 180,
      cellRender: "p-date"
    }
  ];
  return columns;
};
