import { formatToDateTime } from "@wlhy-web-lib/shared";
import * as rule from "@wlhy-web-lib/rule/esm";

// 通用账户数据列表
export const getAccount = () => {
  const account = [
    {
      name: "通用账户余额(元)",
      tooTip: "当前的实时账户余额，可用于消费 除油品、 保险外的所有增值服务"
    },
    {
      name: "消费卡总余额(元)",
      tooTip: "统计当前有效期内的消费卡总余额（消费卡仅支持对应单项服务消费）"
    },
    {
      name: "可开票金额(元)",
      tooTip: "可开发票金额仅针对通用账户已消费金，消费卡开票请联系产业园"
    }
    // {
    //   name: "本月消费(元)",
    //   tooTip: "统计本月增值服务累计消费金额"
    // },
    // {
    //   name: "本月充值(元)",
    //   tooTip: "统计本月增值服务累计充值金额"
    // }
  ];
  return account;
};

//table列配置
export const getColumns = () => {
  const columns = [
    {
      title: "类型",
      dataIndex: "account_service_desc",
      key: "account_service_desc"
    },
    {
      title: "卡号",
      dataIndex: "card_no",
      key: "card_no",
      minWidth: 80
    },
    {
      title: "有效期",
      dataIndex: "valid_end",
      key: "valid_end",
      customRender: text => {
        return text.text && formatToDateTime(text.text, "yyyy.MM.dd");
      }
    },
    {
      title: "面值(元)",
      dataIndex: "recharge_amount",
      key: "recharge_amount",
      customRender: text => {
        return (text.text && +text.text / 100) || "/";
      }
    },
    {
      title: "可用余额(元)",
      dataIndex: "balance",
      key: "balance",
      customRender: text => {
        return text.text && +text.text / 100;
      }
    }
  ];
  return columns;
};

export const getFormRules = () => {
  const rules = rule.object({
    threshold: [
      // rule.string("请输入9位以下的整数").pattern(/^(?:0|[1-9][0-9]{0,8}?|9)$/)
    ],
    message_receiver: [rule.string("手机号码").phone()]
  });
  return {
    ...rules
  };
};
