// 对数字金额做处理
export const amountDispose = number => {
  if (number === undefined) return "--";
  let s = +number && +number / 100;
  s += "";
  if (s.indexOf(".") == -1) s += ".0";
  if (/\.\d$/.test(s)) s += "0";
  while (/\d{4}(\.|,)/.test(s)) s = s.replace(/(\d)(\d{3}(\.|,))/, "$1,$2");
  return s || "0";
};

// 金额分转元
export const fenToYuan = num => {
  if (num === undefined) return "";
  return (+num && +num / 100).toFixed(2);
};
