<template>
  <div
    id="mainColumnar"
    style="width: 100%;height: 300px;background:#fff;border-radius:4px;"
  ></div>
</template>
<script>
import { defineComponent, watch, onMounted } from "vue";
import * as echarts from "echarts";

export default defineComponent({
  props: {
    color: {
      type: String,
      default: ""
    },
    serviceXaxis: Array,
    serviceSeries: Array
  },
  setup(props) {
    watch(props, () => {
      refreshChart();
    });

    const refreshChart = () => {
      const main = document.getElementById("mainColumnar");
      const myChart = echarts.init(main);
      myChart.setOption({
        tooltip: {
          show: true,
          formatter: params => {
            var result = "消费：" + params.value + "元";
            return result;
          }
        },
        backgroundColor: "#fff",
        xAxis: {
          type: "category",
          data: props.serviceXaxis,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true
          }
        },
        yAxis: {
          type: "value",
          show: true,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed"
            }
          }
        },
        grid: {
          left: 60,
          right: 60,
          top: 30,
          bottom: 40
        },
        series: [
          {
            data: props.serviceSeries,
            smooth: true,
            type: "line",
            symbol: "circle",
            lineStyle: {
              width: 3,
              color: {
                type: "linear",
                x: 0.1,
                y: 0.1,
                r: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#F9A465" // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#FF8A21" // 100% 处的颜色
                  }
                ]
              }
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#FEE8D3" // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#fff" // 100% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            itemStyle: {
              bottom: "40px",
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#F9A465"
                },
                {
                  offset: 1,
                  color: "#FF8A21"
                }
              ])
            },
            label: {
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                color: "#808080",
                fontSize: 12
              }
            }
          }
        ]
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    };

    onMounted(() => {
      refreshChart();
    });
    return {};
  }
});
</script>
